<template>
  <div class="case" :class="{'m_top':isMobile == '移动端'}">
    <banner :img="imgUrl" title="新闻资讯"/>
    <div class="case-product">
      <div class="button_top" :class="{'pc_width':isMobile == 'pc端', 'yd_width':isMobile == '移动端'}">
        <el-button @click="btnReturn" icon="el-icon-arrow-left" circle></el-button>
      </div>
      <div class="case-product-content" :class="{'pc_width':isMobile == 'pc端', 'yd_width':isMobile == '移动端'}">
        <p class="product-title">{{caseIdList.newsTitle}}</p>
        <p class="product-time">{{caseIdList.createTime}}</p>
        <p class="product-content" v-html="caseIdList.newsContent"></p>
      </div>
      <div class="button_bottom" :class="{'pc_width':isMobile == 'pc端', 'yd_width':isMobile == '移动端'}">
        <el-button @click="btnReturn" plain icon="el-icon-arrow-left">返回</el-button>
      </div>
    </div>
    <div class="button_sty">
      <el-button @click="btnTop" icon="el-icon-top" circle></el-button>
    </div>
  </div>
</template>

<script>
import { getNewsDetails } from "@/api/index";
import Banner from "../components/Banner";
export default {
  components: {
    Banner
  },
  data() {
    return {
      imgUrl: require("@/assets/images/bage1.png"),
      pid: 0,
      caseIdList: {}
    };
  },
  created() {
    this.pid = this.$route.params.id;
  },
  mounted() {
    window.scrollTo(0,0);
    this.loadData();
  },
  computed: {
    isMobile(){
      return this.$store.state.page.isMobile
    },
  },
  methods: {
    loadData() {
      getNewsDetails({pid: this.pid}).then(response => {
          this.caseIdList = response.data;
        })
        .catch(function(error) {
          this.$message.error('获取详情信息失败！');
        });
    },

    btnReturn(){
      this.$router.go(-1);
    },
    btnTop(){
      window.scrollTo(0,0);
    }
  }
};
</script>

<style lang="scss" scoped>
 .m_top{
   margin-top: 230px;
 }
 .pc_width{
   width: 760px;
 }
 .yd_width{
   width: 1240px;
 }
.case {
  width: 100%;
  height: 100%;
  overflow: hidden;
  &-product {
    width: 1240px;
    margin: 0 auto;
    background-color: #fff;
    //border: 1px solid red;
    &-content {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px 0;
      img {
        width: 100%;
        height: 430px;
      }
      .product-title {
        font-size: 25px;
        color: #e13834;
        padding: 20px 0;
      }
      .product-content {
        font-size: 17px;
        font-weight: bolder;
        padding: 20px 0;
      }
    }
  }
}

// html文章内容
.product-content ::v-deep img{
  width: 100% !important;
}
.product-content ::v-deep svg{
  width: 100% !important;
}

.button_sty{
  position: fixed; 
  right: 50px; 
  bottom: 100px;
}
.button_top{
  margin: 0px auto;
  text-align: right;
  padding-top: 15px;
}
.button_bottom{
  margin: 30px auto;
  text-align: right;
}
</style>